
import { defineComponent } from "vue";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { useRoute } from "vue-router";
import useInvocationDetail from "@/core/services/compositions/invocation/useInvocationDetail";

export default defineComponent({
  components: {
    VueJsonPretty
  },
  setup() {
    const route = useRoute();

    const invocationId = route.params.id;

    const { data } = useInvocationDetail(invocationId);
    return {
      data
    };
  },
  methods: {}
});
