import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-body p-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock("section", null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        (_ctx.data?.data.outbounds)
          ? (_openBlock(), _createBlock(_component_vue_json_pretty, {
              key: 0,
              data: _ctx.data?.data.outbounds,
              deep: 1
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_directive_loading, !_ctx.data]
    ])
  ]))
}